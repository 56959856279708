export default {
  w: new URL('./bus/w.png', import.meta.url).href,
  sw: new URL('./bus/sw.png', import.meta.url).href,
  s: new URL('./bus/s.png', import.meta.url).href,
  se: new URL('./bus/se.png', import.meta.url).href,
  e: new URL('./bus/e.png', import.meta.url).href,
  ne: new URL('./bus/ne.png', import.meta.url).href,
  n: new URL('./bus/n.png', import.meta.url).href,
  nw: new URL('./bus/nw.png', import.meta.url).href
}

