import { createClient as createWSClient } from 'graphql-ws'
import { 
  dedupExchange,
  cacheExchange,
  subscriptionExchange,
  fetchExchange,
  makeOperation,
  createClient,
} from '@urql/core'

import { authExchange } from '@urql/exchange-auth'
import { devtoolsExchange } from '@urql/devtools'
import { GraphQLError } from "graphql"
import { firebaseTools } from './firebase'
import { state } from './localStorage'

const UNAUTHENTICATED_CODE = "validation-failed"

const getAuth = async ({authState}: any) => {
  if (!authState) {
    // The token does not need refresh yet
    console.info('valid token...')
    const token = state.value.jwt
    if (token) {
      return { token }
    }
    return null
  } else {
    const {token:newToken, jwtRefresh}= await firebaseTools.getCurrentUserJWT(true) || ''
    state.value.jwt = newToken
    state.value.jwtRefresh = jwtRefresh
    if (newToken !== '') {
      return { token: newToken }
    } else {
      return null
    }
  }
}

const addAuthToOperation = ({ authState, operation }: any) => {
	if (!authState || !authState.token) {
		return operation
	}
	const fetchOptions =
		typeof operation.context.fetchOptions === "function"
			? operation.context.fetchOptions()
			: operation.context.fetchOptions || {}
	return makeOperation(operation.kind, operation, {
		...operation.context,
		fetchOptions: {
			...fetchOptions,
			headers: {
				...fetchOptions.headers,
				Authorization: `Bearer ${authState.token}`,
			},
      credentials: 'include',
		},
	})
}

const willAuthError = ({ authState }) => {
  if (!authState) return true;
  // e.g. check for expiration, existence of auth etc
  const timeLeft = new Date(state.value.jwtRefresh).getTime() - new Date().getTime()
  if (timeLeft > 0) return true;
  return false;
}

const didAuthError = ({ error }: any) => {
	return error.graphQLErrors.some(
		(e: GraphQLError) => e.extensions?.code === UNAUTHENTICATED_CODE 
	)
}

const wsClient = createWSClient({
  url: 'wss://db.rutasegura.co/v1/graphql',
  retryAttempts: 5,
  connectionParams: async () => {
    const token = (await getAuth({ authState: 'error' }))?.token
    return {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }
  }
})

export const urqlConfig: any = {
  url: 'https://db.rutasegura.co/v1/graphql',
  fetchOptions: {
    headers: {
      'content-type': 'application/json',
    },
  },
  requestPolicy: 'cache-and-network',
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange({
      getAuth,
      addAuthToOperation,
      didAuthError,
      willAuthError,
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => ({
        subscribe: (sink) => ({
          // @ts-ignore
          unsubscribe: wsClient.subscribe(operation, sink),
        }),
      }),
    }),
    devtoolsExchange
  ],
}

export const client = createClient(urqlConfig)