import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '@/stores'
import { firebaseTools } from '@/services/firebase'
import { $vfm } from '@isagaco/vuesaga'
import * as pgArray from 'postgres-array'

const loginRequired = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const { isAuthenticated, isStaff, isOldAPKParent } = useAuthStore()
  if (isOldAPKParent) return '/mandatoryUpdate/'
  if (isAuthenticated) {
    const customClaims = await firebaseTools.getCustomClaims(await firebaseTools.getCurrentUser())
    if (typeof customClaims === 'undefined') return false
    const roles = customClaims['x-hasura-allowed-roles']
    const defaultRole = customClaims['x-hasura-default-role']
    if (isStaff || roles.includes('staff')) {
      if (to.path !== '/staffRoutes/' && to.path !== '/account/' && to.path !== '/ai/' && to.path !== '/qrReader/') return '/staffRoutes/'
      return true
    } else {
      if (roles.includes('instanceAdmin') || roles.includes('support') || roles.includes('user')) {
        if (to.path === '/' && roles.includes('user')) {
          return '/user/map'
        }
        if (['/', '/parcelOTP'].includes(to.path) && (roles.includes('instanceAdmin') || roles.includes('support'))){
          let instanceAdmins
          if (customClaims.version === '2') {
            instanceAdmins = typeof customClaims['x-hasura-admin-instanceIDs'] === 'string' ? 
            pgArray.parse(customClaims['x-hasura-admin-instanceIDs']) : null
          } else {
            instanceAdmins = customClaims['x-hasura-admin-instances']
          }
          const instanceID = instanceAdmins && instanceAdmins.length ? instanceAdmins[0] : '2628d30f-1316-404c-9d7c-38a93c648540'
          return `/admin/${instanceID}/dashboard/`
        }
        return true
      } else {
        // TODO: No roles here
        if (to.path !== '/parcelOTP' && !to.path.includes('/enrollmentForm')) return '/parcelOTP' // TODO: this is forbidden
      }
    }
  }
  if (!isAuthenticated) return `/login?refURL=${to.fullPath !== '/login' ? to.fullPath : ''}`
  return true
}

// const frillRedirect = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
//   const { isAuthenticated } = useAuthStore()
//   if (isAuthenticated) {
//     if (to.query.frillRedirect) {
//       return `/sso/frill/?redirect=${to.query.redirect}`
//     } else {
//       return '/'
//     }
//   }
//   return true
// }

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../views/NotFound.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/mandatoryUpdate/',
    component: () => import('../views/mandatoryUpdate.vue'),
    beforeEnter: [()=>{
      const { isOldAPKParent } = useAuthStore()
      if (!isOldAPKParent) return '/'
    }]
  },
  {
    path: '/ai/',
    component: () => import('../views/ai.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/parcelOTP',
    component: () => import('../views/parcelOTP.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/staffRoutes/',
    component: () => import('../views/staffRoutes.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/qrReader/',
    component: () => import('../views/qrReader.vue'),
  },
  {
    path: '/sso/Frill',
    component: () => import('../views/ssoFrill.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/enrollmentForm/:customerAlias/:contract',
    name: 'Matrículas',
    component: () => import('../views/enrollmentForm.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/routeRequestForm/:customerAlias/:contract',
    name: 'Solicitud de servicios',
    component: () => import('../views/routeRequestForm.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/enrollment/:customerAlias/:contract',
    name: 'Bienvenida Matrículas',
    component: () => import('../views/enrollment.vue'),
  },
  {
    path: '/routeRequest/:customerAlias/:contract',
    name: 'Bienvenida Solicitud de Servicios',
    component: () => import('../views/routeRequest.vue'),
  },
  {
    path: '/admin/:instanceID/contract/',
    name: 'Contrato',
    component: () => import('../views/contract.vue'),
    beforeEnter: [loginRequired],
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound', 
    component: () => import('../views/NotFound.vue'),
  },
  {
    path: '/gmap/',
    name: 'google-map',
    component: () => import('../views/gmap.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/admin/:instanceID/optimization/',
    name: 'Optimización',
    component: () => import('../views/optimization.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/admin/:instanceID/geofences/',
    name: 'Paradas',
    component: () => import('../views/geofences.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/admin/:instanceID/tags/',
    name: 'Etiquetas',
    component: () => import('../views/tags.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/admin/:instanceID/temporals/',
    name: 'Novedades',
    component: () => import('../views/temporals.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/admin/:instanceID/roles/',
    name: 'Roles',
    component: () => import('../views/roles.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/admin/:instanceID/chat/:roomID?/',
    name: 'Chat Admin con conversación',
    component: () => import('../views/chat.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/user/chat/:roomID?/',
    name: 'Chat Usuario',
    component: () => import('../views/chat.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/admin/:instanceID/notifications/',
    name: 'Comunicados',
    component: () => import('../views/notifications.vue'),
  },
  {
    path: '/admin/:instanceID/history/',
    name: 'Históricos',
    component: () => import('../views/history.vue'),
  },
  {
    path: '/admin/:instanceID/tower/',
    name: 'Torre de control',
    component: () => import('../views/tower.vue'),
  },
  {
    path: '/admin/:instanceID/data/:itemType',
    name: 'Datos',
    component: () => import('../views/table.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/admin/:instanceID/data/:itemType/:itemID',
    name: 'Detalles',
    component: () => import('../views/details.vue'),
    meta: { transition: 'slide-left' },
    beforeEnter: [loginRequired],
  },
  {
    path: '/admin/:instanceID/dashboard',
    name: 'Tablero',
    component: () => import('../views/dashboard.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/supportDashboard',
    name: 'Expertos',
    component: () => import('../views/supportDashboard.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/admin/:instanceID/cancelationInbox',
    name: 'Buzón Ausencias',
    component: () => import('../views/cancelationInbox.vue'),
  },
  {
    path: '/user/map/',
    name: 'Acudiente',
    component: () => import('../views/user.vue'),
    beforeEnter: [loginRequired],
  },
  // {
  //   path: '/user/public/',
  //   name: 'Mapa público',
  //   component: () => import('../views/userPublicMap.vue'),
  // },
  {
    path: '/user/inbox/',
    name: 'Buzón',
    component: () => import('../views/inbox.vue'),
  },
  {
    path: '/user/chat/:roomID?/',
    name: 'Chat Usuario con conversación',
    component: () => import('../views/chat.vue'),
    beforeEnter: [loginRequired],
  },
  {
    path: '/account/',
    name: 'Cuenta',
    component: () => import('../views/account.vue'),
    beforeEnter: [loginRequired]
  },
  {
    path: '/staff/route/:instanceID/:routeID/:routeType/',
    name: 'Monitora',
    component: () => import('../views/staffStream.vue'),
  },
  {
    path: '/user/news/',
    name: 'Noticias',
    component: () => import('../views/news.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from) => {
  if (typeof StatusBar !== 'undefined') {
    if (to.path === '/account/' && window.os === 'ios') StatusBar.styleLightContent()
    if (from.path === '/account/') StatusBar.styleDefault()
  }
  if ($vfm.openedModals.length > 0) {
    $vfm.hideAll()
    return false
  }
})

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
})

router.onError(err => {console.error(err)})

export default router
