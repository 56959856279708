<template>
  <div v-if="!authStore.isAuthenticated || (authStore.isAuthenticated && authStore.account)" class="w-full flex flex-col sm:!flex-row">
    <vs-omni-search
      v-model="isSearching"
      :results="searchResults"
      @search:changed="runSearch"
      @search:select="$router.push($event.path)"
    />
    <vs-instance-picker
      v-model="showInstancePicker"
      :instances="authStore.instances"
      :currentInstanceID="authStore.instanceID"
      @instance:selected="authStore.setInstanceID($event)"
    />
    <vs-sidebar
      class=""
      v-if="authStore.isAdmin"
      :pages="adminPages"
      :active-page="activePage"
      @page:select="handlePageSelect"
    >
      <template #header="{ isCollapsed, isSmall }">
        <div class="flex items-center w-full justify-center h-20 mb-2">
          <img src="@/assets/logo.svg" :width="isCollapsed && !isSmall ? 40 : 50" />
        </div>
        <div class="mb-5 flex justify-center w-full">
          <vs-avatar
            class="cursor-pointer hover:brightness-90"
            :name="currentInstance?.name"
            @click="showInstancePicker = true"
          />
        </div>
        <div
          class="mx-4 mb-2 bg-surface px-2 py-2 flex items-center rounded cursor-pointer transition"
          :class="isCollapsed ? 'flex justify-center' : ''"
          @click="isSearching = true"
        >
          <vs-icon class="opacity-50 mr-2" name="akar-icons:search" />
          <div v-if="!isCollapsed || isSmall">
            Buscar
          </div>
        </div>
      </template>
    </vs-sidebar>
    <div 
      class="overflow-y-auto sm:w-9/12 sm:min-w-150 w-full flex flex-col flex-grow pt-safe"
      :class="authStore.isAdmin ? 'h-[calc(100vh-46px-env(safe-area-inset-bottom))] sm:h-screen' : 'h-screen'"  
    >
      <Suspense>
        <div
          class="relative flex-grow flex-shrink overflow-auto"
          :class="authStore.roles.includes('user') && !authStore.isAdmin ? 'sm:pb-0' : ''"
        >
          <router-view
            :key="$route.path.split('chat').length > 1 ? 'chat' : $route.path"
            v-slot="{ Component }"
          >
            <transition 
              :name="route.meta.transitionName"
              mode="out-in"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </Suspense>
      <div v-if="uiStore.map.teleportTo">
        <Teleport
          :to="uiStore.map.teleportTo"
        >
          <vs-map
            v-bind="uiStore.map.attrs"
            v-if="!os"
            @location:confirm="uiStore.map.attrs['v-on:location:confirm']"
            @ready="uiStore.setReady()"
            @click="handleMapClick"
            @marker:drag="handleMarkerDrag"
            @marker:infoClick="handleInfoClick"
          />
        </Teleport>
      </div>
      <vs-toolbar
        v-if="authStore.roles.includes('user') && !authStore.isAdmin"
       :pages="userPages" @page:select="handlePageSelect"
      ></vs-toolbar>
    </div>
  </div>
  <div v-else class="mt-20 flex flex-col justify-center items-center">
    <vs-preloader :size="32"/>
    <div>{{authStore.user?.phoneNumber || authStore.user?.email}}</div>
  </div>
  <modals-container></modals-container>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onBeforeMount, onMounted, computed, inject, Ref } from 'vue'
import { storeToRefs } from 'pinia'
import { firebaseTools, getUnreads } from '@/services/firebase'
import { isSupported } from '@firebase/messaging'
import { useAuthStore, useUIStore } from '@/stores'
import { useRouter, useRoute } from 'vue-router'
import { pipe, subscribe } from 'wonka'
import { getAccount } from '@/services/queries'
import methods from '@/utils/methods'
import { client } from '@/services/typesense'
import { search } from "ss-search"
import { gql, useClientHandle } from '@urql/vue'
import { state } from '@/services/localStorage'
import { createToast, withProps } from 'mosha-vue-toastify'
import { vsToast } from '@isagaco/vuesaga'
import { useEventBus } from '@vueuse/core'
import { vsOmniSearch, vsInstancePicker, vsSidebar, vsAvatar, vsIcon, vsMap, vsToolbar, vsPreloader } from '@isagaco/vuesaga'

window.googleApiLoaded = (e)=>{
  window.googleApi = true
}

export default defineComponent({
  name: 'App',
  components: {
    vsOmniSearch, vsInstancePicker, vsSidebar, vsAvatar, vsIcon, vsMap, vsToolbar, vsPreloader
  },
  setup () {
    const authStore = useAuthStore()
    const uiStore = useUIStore()
    const clientHandle = useClientHandle()
    const $crisp = window.$crisp
    const instances = computed(() => {
      return authStore.userInstances?.map(instance => instance.name)
    })
    const currentInstance = computed(() => {
      return authStore.instances.find(instance => {
        return instance.id === authStore.instanceID
      })
    })
    const parcels = computed(() => {
      return authStore.parcels?.map(parcel => {
        let str = `${parcel.name}: \n•${parcel.instance.name}\n`
        if (parcel.routes) {
          str += parcel.routes?.map(route=> '○' + route.name).join('\n')
        }
        return str
      }).join('\n\n')
    })
    watch(instances, () => {
      $crisp.push(['set', 'session:segments', [instances.value]])
    })
    watch(parcels, () => {
      $crisp.push(['set', 'session:data', [[['Pasajeros', parcels.value]]]])
    })
    const hideCrisp = () => {
      $crisp.push(['do', 'chat:hide'])
    }
    const setCrispToken = () => {
      const token = $crisp.get('session:identifier')
      if (token) setToken(token, 'crisp')
    }
    const setCrisp = () => {
      // TODO: this should be done in chat view, also inject javascript there
      window.CRISP_TOKEN_ID = authStore.account?.id
      $crisp.push(['do', 'session:reset'])
      $crisp.push(['set', 'user:nickname', authStore.account?.name])
      $crisp.push(['set', 'user:email', authStore.account?.email])
      $crisp.push(['set', 'user:phone', authStore.account?.phone])
      $crisp.push(['on', 'session:loaded', setCrispToken])
      $crisp.push(['on', 'chat:initiated', setCrispToken])
      $crisp.push(['on', 'chat:closed', hideCrisp])
      hideCrisp()
    }
    const $vfm:any = inject('$vfm')
    onMounted(async () => {
      // uiStore.setMapTeleport('#mapTeleport')
      // TODO: this should be done in the the vs-address component pass token and use there
      if(!window.googleApi) {
        let places = document.createElement('script')
        places.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDDtmcTqrihzaWY6BPyaZDKKu7jQm51Gbg&libraries=places&callback=googleApiLoaded')
        places.setAttribute('type', 'text/javascript')
        places.setAttribute('async', 'async') // TODO: MOVE TO VS-MAP AND LOAD ASYNC WITH GLOBAL CALLBACK
        document.getElementsByTagName('head')[0].appendChild(places)
      }
    })
    const unsubscribeBind: Ref<any> = ref(null)
    const notificationListener: Ref<any> = ref(null)
    watch(() => state.value.jwt, async (val, old) => {
      // Get user
      const currentUser = await firebaseTools.getCurrentUser()
      if (currentUser) {
        await authStore.setUser(currentUser)
      }
      const customClaims = await firebaseTools.getCustomClaims(currentUser)
      if (typeof customClaims === 'undefined' || customClaims.version !== '2') return // wait for custom claims
      const role = customClaims['x-hasura-default-role']
      await authStore.setUser(currentUser)
      if (authStore.account === null) {
        if (unsubscribeBind.value) unsubscribeBind.value()
        const { unsubscribe } = pipe(
          clientHandle.client.query(getAccount(role), { firebaseID: currentUser.uid }),
          subscribe(async result => {
            if (result.data?.users) {
              await authStore.setAccount(result.data.users[0], customClaims)
              setCrisp()
              if (await isSupported() && !window.os) {
                const permission = await Notification.requestPermission()
                if(permission === 'granted') {
                  const token = await firebaseTools.getMessagingToken()
                  if (token) {
                    await setToken(token, 'firebase')
                    if (!notificationListener.value) {
                      // console.info('setting notification listener')
                      notificationListener.value = firebaseTools.onNotificationListener((payload:any)=>{
                        console.info('notification Listener', payload)
                        authStore.setLastPushTimestamp(Date.now())
                        console.info(authStore.lastPushTimestamp)
                        createToast(withProps(vsToast, { type: payload.data.type || 'info', text: payload.data.body || ''}), {timeout: -1})
                      })
                    }
                  }
                }
              }
              authStore.setParcelData(result.data.users[0].parcels || [])
              await updateLastSeen()
              if (fcmToken.value) {
                setToken(fcmToken.value, 'fcm')
              }
              if (!fcmToken.value && window.os) {
                await authStore.getNotificationsToken()
              }
              const refURL = route.query.refURL
              if (route.path === '/login') {
                router.push(`${refURL ? refURL : '/'}`)
              }
              // if ((!authStore.account.name || !authStore.account.phone) && !route.path.includes('/enrollment')) {
              //   router.push({ path: '/account/', hash: '#onBoarding'})
              // }
            }
          })
        )
        unsubscribeBind.value = unsubscribe
      }
    }, {deep: true})
    const setViewHeight = () => {
      let vh = window.innerHeight
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    onBeforeMount(async () => {
      setViewHeight()
      window.addEventListener('resize', () => {
        setViewHeight()
      })
      const user = await firebaseTools.getCurrentUser()
      if (user) {
        await authStore.setUser(user)
        const customClaims = await firebaseTools.getCustomClaims(user)
        if (typeof customClaims === 'undefined' || customClaims.version !== '2') return // wait for custom claims
        const role = customClaims['x-hasura-default-role']
        await authStore.setUser(user)
        if (authStore.account === null) {
          if (unsubscribeBind.value) unsubscribeBind.value()
          const { unsubscribe } = pipe(
            clientHandle.client.query(getAccount(role), { firebaseID: user.uid }),
            subscribe(async result => {
              if (result.data?.users) {
                await authStore.setAccount(result.data.users[0], customClaims)
                setCrisp()
                authStore.setParcelData(result.data.users[0].parcels || [])
                await updateLastSeen()
                if (fcmToken.value) {
                  setToken(fcmToken.value, 'fcm')
                }
                if (!fcmToken.value && window.os) {
                  await authStore.getNotificationsToken()
                }
                if (await isSupported() && !window.os){
                  const permission = await Notification.requestPermission()
                  if(permission === 'granted') {
                    const token = await firebaseTools.getMessagingToken()
                    if (token) {
                      console.info(token)
                      await setToken(token, 'firebase')
                      firebaseTools.onNotifcationListener((payload:any)=>{
                        console.info('listener', payload)
                      })
                    }
                  }
                }
                // if ((!authStore.account.name || !authStore.account.phone) && !route.path.includes('/enrollment')) {
                //   router.push({ path: '/account/', hash: '#onBoarding'})
                // }
              }
            })
          )
          unsubscribeBind.value = unsubscribe
        }
      } else {
        console.info('no user')
      }
    })
    const { fcmToken } = storeToRefs(authStore)
    watch(fcmToken, ()=>{
      if(fcmToken.value) {
        setToken(fcmToken.value, 'fcm')
      }
    })
    const router = useRouter()
    const route = useRoute()
    const instanceID = computed(() => {
      return authStore.instanceID
    })
    const URLInstanceID = computed(() => {
      return route.params.instanceID
    })
    watch(URLInstanceID, () => {
      if (!URLInstanceID.value || URLInstanceID.value === 'undefined') {
        return
      }
      authStore.setInstanceID(URLInstanceID.value)
    })
    const unreads = ref()
    watch(authStore.account?.id, async () => {
      unreads.value = await getUnreads(instanceID.value, authStore.account?.id)
    }, { immediate: true })
    watch(instanceID, async (after, before) => {
      unreads.value = await getUnreads(instanceID.value, authStore.account?.id)
      if (!before) {
        router.push({ name: route.name, params: { ...route.params }, query: route.query })
        return
      }
      // console.info(before, after)
      router.push({ path: route.path.replace(before, after) })
    })
    const userPages = ref(
      [
        {
          name: 'Inicio',
          icon: 'bxs:home-alt-2',
          path: '/user/map',
          id: 'user'
        },
        {
          name: 'Notificaciones',
          icon: 'ion:notifications-sharp',
          path: '/user/inbox/',
          id: 'inbox'
        },
        {
          name: 'Chat',
          icon: 'bi:chat-fill',
          path: '/user/chat/',
          id: 'chat',
          alert: !!unreads.value
        },
        // {
        //   name: 'Noticias',
        //   icon: 'teenyicons:gift-solid',
        //   path: '/user/news/',
        //   id: 'news'
        // },
        {
          name: 'Cuenta',
          icon: 'carbon:user-avatar-filled',
          path: '/account/',
          id: 'account'
        },
      ])
    const adminPages = computed(() => {
      return [
        ...(authStore.isSupport ? [{
          name: 'Expertos',
          icon: 'mdi:crown',
          path: `/supportDashboard/`,
          id: 'supportDashboard',
          pinned: true,
        }] : []),
        ...(authStore.isSupport ? [{
          name: 'Vision artificial',
          icon: 'ic:round-remove-red-eye',
          path: `/ai/`,
          id: 'ai',
        }] : []),
        {
          name: 'Tablero',
          icon: 'bi:bar-chart-fill',
          path: `/admin/${instanceID.value}/dashboard/`,
          id: 'dashboard',
          pinned: true,
        },
        {
          name: 'Datos',
          icon: 'bx:bxs-data',
          id: 'data',
          group: true,
          pages: [
            {
              name: 'Rutas',
              id: 'routes',
              icon: 'eos-icons:route',
              path: `/admin/${instanceID.value}/data/routes`,
            },
            {
              name: 'Pasajeros',
              id: 'parcels',
              icon: 'healthicons:child-program',
              path: `/admin/${instanceID.value}/data/parcels`,
            },
            {
              name: 'Vehículos',
              id: 'vehicles',
              icon: 'fa-solid:shuttle-van',
              path: `/admin/${instanceID.value}/data/vehicles`,
            },
            {
              name: 'Personal',
              id: 'staff',
              icon: 'fluent:people-16-filled',
              path: `/admin/${instanceID.value}/data/staff`,
            },
            {
              name: 'Lugares',
              id: 'places',
              icon: 'ic:round-place',
              path: `/admin/${instanceID.value}/data/places`,
            },
          ]
        },
        // {
        //   name: 'Paradas',
        //   id: 'stops',
        //   icon: 'mdi:map-marker',
        //   path: `/admin/${instanceID.value}/stopViewer/`,
        // },
        {
          name: 'Novedades',
          id: 'temporals',
          icon: 'ic:round-change-circle',
          path: `/admin/${instanceID.value}/temporals/`,
        },
        // {
        //   name: 'Mapa',
        //   icon: 'bxs:map',
        //   path: '/map/',
        //   id: 'map'
        // },
        // {
        //   name: 'Etiquetas',
        //   id: 'tags',
        //   icon: 'el:tag',
        //   path: `/admin/${instanceID.value}/tags/`,
        // },
        // {
        //   name: 'Mapa',
        //   icon: 'bxs:map',
        //   path: `/admin/${instanceID.value}/map/`,
        //   id: 'map'
        // },
        ...(!authStore.isReadOnly ? [{
          name: 'Optimización',
          icon: 'ic:sharp-rocket-launch',
          path: `/admin/${instanceID.value}/optimization/`,
          id: 'optimization'
        }] : []),
        ...(!authStore.isReadOnly ? [{
          name: 'Chat',
          icon: 'bi:chat-fill',
          path: `/admin/${instanceID.value}/chat/`,
          id: 'chat',
          pinned: true,
          alert: !!unreads.value
        }] : []),
        // {
        //   name: 'Novedades',
        //   icon: 'bi:calendar-event-fill',
        //   path: '/chat/',
        //   id: 'noti'
        // },
        {
          name: 'Comunicados',
          icon: 'ant-design:notification-filled',
          path: `/admin/${instanceID.value}/notifications/`,
          id: 'notifications'
        },
        {
          name: 'Histórico',
          icon: 'eos-icons:route',
          path: `/admin/${instanceID.value}/history/`,
          id: 'history',
          pinned: true,
        },
        {
          name: 'Torre de control',
          icon: 'ic:baseline-cell-tower',
          path: `/admin/${instanceID.value}/tower/`,
          id: 'tower',
          pinned: true,
        },
        {
          name: 'Configuración',
          icon: 'ic:baseline-settings',
          id: 'config',
          group: true,
          pages: [
          {
              name: 'Contrato',
              icon: 'clarity:list-solid',
              path: `/admin/${instanceID.value}/contract/`,
              id: 'contract'
            },
            {
              name: 'Roles',
              icon: 'heroicons:users-20-solid',
              path: `/admin/${instanceID.value}/roles/`,
              id: 'roles'
            },
            {
              name: 'Cuenta',
              icon: 'heroicons:user-20-solid',
              path: '/account/',
              id: 'account'
            },
          ]
        },
      ]
    }) as any
    const activePage = ref('')
    watch(route, () => {
      activePage.value = (route.path as string) || ''
      uiStore.setMapAttrs({})
    })
    const handlePageSelect = (page: Record<string, string>) => {
      if (page.group) {
        if (page.pages) {
          router.push(page.pages[0]?.path)
        }
      }
      if (!page.path) return
      router.push(page.path)
    }
    const setToken = async (token, type) => {
      const response = await methods.mutation([{
        action: 'insert',
        table: 'tokens',
        data: {
          userID: authStore.account?.id,
          token,
          type
        }
      }], null, null, true)
      // console.info(response)
    }
    const updateLastSeen = async () => {
      if (authStore.account) {
        await methods.mutation([{
          action: 'update',
          table: 'users',
          pkey: `id: "${authStore.account.id}"`,
          data: {
            lastSeen: new Date(),
          },
          returnKeys: 'id'
        }], null, null, true)
      }
    }
    const isSearching = ref(false)
    const showInstancePicker = ref(false)
    const searchResults = ref()
    const runSearch = async (e) => {
      if (!e) {
        searchResults.value = null
        return
      }
      const searchRequests = {
        searches: [
          {
            collection: 'parcels',
            query_by: 'name,documentID'
          },
          {
            collection: 'routes',
            query_by: 'name'
          },
          {
            collection: 'vehicles',
            query_by: 'name'
          },
          {
            collection: 'staff',
            query_by: 'name,phone'
          },
        ]
      }
      const commonSearchParams =  {
        q: e,
        filter_by: `instanceID:=${instanceID.value}`,
        prioritize_exact_match: true,
      }
      const searchResult = await client.multiSearch.perform(searchRequests, commonSearchParams)
      const tables = [
        {
          name:'Pasajeros',
          id: 'parcels',
        },
        {
          name:'Rutas',
          id: 'routes',
        },
        {
          name:'Vehículos',
          id: 'vehicles',
        },
        {
          name:'Personal',
          id: 'staff',
        },
      ]
      const routerResults = search(router.options.routes, ['name'], e)
      searchResults.value = [
        ...(await Promise.all(searchResult.results.map(async (result, index) => {
          let parcelAvatars = null
          if (tables[index].name === 'Pasajeros') {
            const parcelIDs = result.hits?.map(hit => `"${hit.document.id}"`)
            const query = gql`
              query {
                parcels(where: {id: {_in: [${parcelIDs}]}}) {
                  id
                  avatar
                }
              }
            `
            const {data} = await clientHandle.client.query(query).toPromise()
            parcelAvatars = data.parcels
          }
          return {
            name: tables[index].name,
            avatar: !index,
            items: result?.hits.map(hit => {
              const document = hit.document
              return {
                avatar: parcelAvatars?.find(parcel => parcel.id === document.id)?.avatar,
                name: document.name,
                footer: document.documentID || document.phone,
                mark: hit.highlights[0]?.snippet.split('>')[1].split('<')[0],
                id: document.id,
                path: `/admin/${instanceID.value}/data/${tables[index].id}/${document.id}`,
              }
            })
          }
        }))),
        {
          name: 'Navegación',
          avatar: false,
          items: routerResults.map(result => {
            return {
              name: result.name,
              mark: e,
              path: result.path,
              icon: 'akar-icons:link-chain',
            }
          })
        }
      ]
    }
    const { emit: mapClickEmit } = useEventBus<string>('map:click')
    const handleMapClick = (e) => {
      mapClickEmit(e)
    }
    const { emit: markerDragEmit } = useEventBus<string>('marker:drag')
    const handleMarkerDrag = (e) => {
      markerDragEmit(e)
    }
    const { emit: infoClickEmit } = useEventBus<string>('marker:infoClick')
    const handleInfoClick = (e) => {
      infoClickEmit(e)
    }
    return {
      uiStore,
      route,
      currentInstance,
      userPages,
      adminPages,
      activePage,
      handlePageSelect,
      authStore,
      isSearching,
      showInstancePicker,
      searchResults,
      runSearch,
      handleMapClick,
      handleMarkerDrag,
      handleInfoClick
    }
  }
})
</script>

<style scoped>
.slide-left-enter-active, .slide-left-leave-active {
  transition: all 0.3s ease;
}

.slide-left-enter-from, .slide-left-leave-active {
  transform: translateX(-2em);
  opacity: 0;
}

.slide-right-enter-active, .slide-right-leave-active {
  transition: all 0.3s ease;
}

.slide-right-enter-from, .slide-right-leave-active {
  transform: translateX(2em);
  opacity: 0;
}
</style>