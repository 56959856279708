import { initializeApp } from 'firebase/app'
// import { initializeAppCheck, ReCaptchaV3Provider, getToken } from "firebase/app-check"
import axios from 'axios'
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithCredential,
  OAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  User,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth'
import { getStorage, ref as sRef, getBlob } from "firebase/storage"
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config"
import { getDatabase, ref, onValue, get, DatabaseReference, DataSnapshot, goOffline, goOnline } from "firebase/database"
import {getFirestore, limit, getDocs, query, collection, where} from "firebase/firestore"
import { getAnalytics } from 'firebase/analytics'
import {getMessaging, getToken, onMessage} from "firebase/messaging"
import {$vfm, vsModal} from '@isagaco/vuesaga'
import { useAuthStore } from '@/stores/auth'
import methods from '@/utils/methods'

const firebaseConfig = {
  apiKey: "AIzaSyB_cKWxEKSgBGI4Lp6kdj5BGBheFBL2TNI",
  authDomain: "auth.rutasegura.co",
  databaseURL: "https://buseticas-7f9fd.firebaseio.com",
  projectId: "buseticas-7f9fd",
  storageBucket: "buseticas-7f9fd.appspot.com",
  messagingSenderId: "193142801973",
  appId: "1:193142801973:web:bb48f859394b89031a537c"
}
let apiURL = ''
if (process.env.NODE_ENV === 'production') {
  apiURL = 'https://us-central1-isaga-tracking.cloudfunctions.net/api'
} else {
  apiURL = 'http://localhost:5002/isaga-tracking/us-central1/api'
}
export { apiURL, firebaseConfig }

export const firebaseApp = initializeApp(firebaseConfig)
export const storage = getStorage(firebaseApp)
export const firebaseAuth = getAuth()
const remoteConfig = getRemoteConfig(firebaseApp)
remoteConfig.settings.minimumFetchIntervalMillis = 3600000


if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  // self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
  // connectAuthEmulator(firebaseAuth, "http://localhost:9099")
}

// TODO: add this capability to firebase plugin
const reCaptcha = location.host.includes('development.ruta-segura.pages.dev')
  ? '6Lf1ayIhAAAAAL6UVKIG7PHOVNS-m1Qhfo2pe9GD'
  : '6LcblMEfAAAAAEVnjZEaPpry27u1aM4E1Jx8YG2z'

// const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider(reCaptcha),
//   isTokenAutoRefreshEnabled: true
// })

// axios.interceptors.request.use(async function (config) {
//   const appCheckResponse = await getToken(appCheck, false)
//   config.headers['x-firebase-appcheck'] = appCheckResponse.token
//   return config
// }, function (error) {
//   return Promise.reject(error)
// })
// if (location.host === 'matricula.rutasegura.co' || location.host === 'localhost:3000'){
// }

const rtdbInit = getDatabase()
export const rtdb = rtdbInit

getAnalytics()

// firestore
export const db = getFirestore()
const ROOMS_PATH = 'chatRooms'
const roomsRef = collection(db, ROOMS_PATH)
export const getUnreads = async (instanceID: string, userID: string) => {
  const type = instanceID ? 'instance' : 'user'
  if (!userID) return
  const unreadQuery =
  instanceID ?
    query(roomsRef, where('instanceID', '==', instanceID), where(`${type}UnreadCount`, '>=', 1), limit(1))
  :
    query(roomsRef, where(`${type}UnreadCount`, '>=', 1), where('users', 'array-contains', userID), limit(1))
  const unreadDocs = await getDocs(unreadQuery)
  return unreadDocs.docs.length
}

const onResume = () => {
  console.info('onResume')
  try {
    goOnline(rtdb)
  } catch (error) {
    console.error(error)
  }
}
const onActive = () => {
  console.info('onActive')
  console.info('onResume')
  try {
    goOnline(rtdb)
  } catch (error) {
    console.error(error)
  }
}
const onResign = () => {
  console.info('onResign')
  try {
    goOffline(rtdb)
  } catch (error) {
    console.error(error)
  }
}
const onPause = () => {
  console.info('onPause, going offline')
  try {
    goOffline(rtdb)
  } catch (error) {
    console.error(error)
  }
}


document.addEventListener("resume", onResume, false)
document.addEventListener("active", onActive, false)
document.addEventListener("pause", onPause, false)
document.addEventListener("resign", onResign, false)

export let firebaseTools = {
  getStorageBlob: async (path: string) => {
    try {
      return await getBlob(sRef(storage, path))
    } catch (error) {
      console.error(error)
      alert(error.code)
    }
  },
  getRemoteConfigValue: async (key: string) => {
    await fetchAndActivate(remoteConfig)
    return getValue(remoteConfig, key)
  },
  onNotificationListener: (_cb: any) => {
    const messaging = getMessaging(firebaseApp)
    return onMessage(messaging, (payload) => {
      _cb(payload)
    })
  },
  getMessagingToken: async () => {
    const messaging = getMessaging(firebaseApp)
    let token = null
    try {
      token = await getToken(messaging, { vapidKey: 'BIlquV66n_KWICeFJGtoDf-Mtp4aDyGqhTA38ZAAtKr0ewAT3zdrlmRh2x9jJ1gr0nwjsbeDE0APHsl0fw2btBc' })
      if (!token) console.info('No registration token available. Request permission to generate one.')
    } catch (error) {
      console.error(error)
    }
    return token
  },
  getCurrentUser: async () => firebaseAuth.currentUser,
  onIdTokenChanged: (_cb: any) => {
    firebaseAuth.onIdTokenChanged((user) => {
      _cb(user)
    })
  },
  getRef(dir: string) {
    return ref(rtdb, dir)
  },
  listen(ref: DatabaseReference, callback: (snapshot: DataSnapshot) => void) {
    return onValue(ref, callback)
  },
  get(ref: DatabaseReference) {
    return get(ref)
  },
  signInWithCredential: async(credential) => {
    console.info('using default signInWithCredential')
    const result = await signInWithCredential(firebaseAuth, credential)
    const user = result.user
    return {user, token: credential}
  },
  signUp: async ({email, password, name}: {email: string, password: string, name: string}) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password)
      const user = userCredential.user
      await updateProfile(user, {
        displayName: name
      })
      // TODO: CALL FUNCTION TO UPDATE USER IN HASURA
      const token = await userCredential.user.getIdToken(false)
      return { user, token }
    } catch (error) {
      console.error(error)
    }
  },
  getCurrentUserJWT: async (shouldRefresh?: boolean) => {
    const user = firebaseAuth.currentUser
    const refresh = typeof shouldRefresh === 'undefined' ? true : shouldRefresh 
    if (user) {
      const token = await user.getIdToken(refresh)
      const jwtRefresh = await user.getIdTokenResult()
      return { token, jwtRefresh: jwtRefresh.expirationTime }
    }
    return { token: '', jwtRefresh: '' }
  },
  getCustomClaims: async (user: User) => {
    const testToken = await user.getIdTokenResult()
    if (typeof testToken.claims === 'undefined') return {}
    return testToken?.claims['https://hasura.io/jwt/claims'] as Record<string, unknown>
  },
  signOut: async (path='/') => {
    await firebaseAuth.signOut()
    if (typeof FirebasePlugin !== 'undefined') {
      FirebasePlugin.signOutUser(() => {
        FirebasePlugin.unregister()
        rejPlug('Error signing in')
      }, () => {console.info('logout cord')})
    }
    if (localStorage) {
      localStorage.removeItem('askedNotificationPermission')
      localStorage.removeItem('askedBackgroundPermission')
    }
    let hash
    if (window.apk) {
      hash = window.os ? `/#${window.os}/${window.apkVersion}/${window.apk}` : ''
    } else {
      hash = window.os ? `/#${window.os}/${window.apkVersion}/` : ''
    }
    if(path.at(-1) === '/') path = path.slice(0, -1)
    window.location.href = window.location.origin + path + hash
  }
}

export const initFirebase = async () => {
  return new Promise((res,rej)=>{
    // Must inject all plugins
    let os = location.hash.slice(1, location.hash.length).split('/')[0]
    let version = location.hash.slice(1, location.hash.length).split('/')[1] || '0.0.0'
    let apk = location.hash.slice(1, location.hash.length).split('/')[2]
    
    if (os === 'android' || os === 'ios') {
      let system = ''
      if (typeof apk !== 'undefined') {
        system = `/${os}/${apk}/${version}/cordova.js`
      } else {
        system = `/${os}/${version}/cordova.js`
      }
      let cordovaScript = document.createElement('script')
      cordovaScript.setAttribute('src', system)
      cordovaScript.setAttribute('type', 'text/javascript')
      document.getElementsByTagName('head')[0].appendChild(cordovaScript)
      document.addEventListener('deviceready', async () => {
        window.os = os
        window.apkVersion = version
        window.apk = apk
        if (typeof navigator.splashscreen !== 'undefined') navigator.splashscreen.hide()
        StatusBar.styleDefault()
        if (os === 'android') {
          StatusBar.overlaysWebView(false)
          StatusBar.styleDefault()
          StatusBar.backgroundColorByHexString("#FFFFFF")
        } else {
          StatusBar.overlaysWebView(true)  
        }
        firebaseTools['getNotificationsToken'] = async () => {
          const authStore = useAuthStore()
          console.info('setting listeners')
          return new Promise((resPlug, rejPlug) => {
            if(authStore.tokenListener) {
              console.info(authStore.tokenListener, 'ALREADY LISTENING')
              resPlug(true)
              return
            } else {
              console.info('setting listeners')
              FirebasePlugin.onMessageReceived(function(message) {
                console.info('onMessageReceived', message)
                if(message.messageType === "notification"){
                  if (message.tap === 'background') {
                    if (message.aps.alert.title === 'Soporte' || message.title === 'Soporte') {
                      console.info('opening chat')
                      window.$crisp.push(['do', 'chat:show'])
                      window.$crisp.push(['do', 'chat:open'])
                    }
                  }
                  authStore.setLastPushTimestamp(Date.now())
                }
              }, function(error) {
                console.error(error);
              });
              FirebasePlugin.hasPermission(function(hasPermission){
                console.info('hasPermission', hasPermission)
                if (!hasPermission) {
                  FirebasePlugin.grantPermission(function(granted){
                    console.info('granted', granted)
                    if (granted) {
                      if (window.os === 'android') {
                        console.info('android device asking permission')
                        FirebasePlugin.setDefaultChannel({
                            id: 'fcm_default_channel',
                            name: 'Default'
                          },
                          function(){
                            authStore.tokenListener = true
                            FirebasePlugin.onTokenRefresh(async (fcmToken) => {
                              // Refresh fcm token
                              authStore.fcmToken = fcmToken
                              const response = await methods.mutation([{
                                action: 'insert',
                                table: 'tokens',
                                data: {
                                  userID: authStore.account?.id,
                                  token: fcmToken,
                                  type: 'fcm'
                                }
                              }], null, null, true)
                              console.info(response)
                            }, function(error) {
                                console.error(error);
                            });
                            resPlug(true)
                          },
                          function(error){
                            rejPlug(error)
                          })
                      } else {
                        console.info('getting ios fcm token')
                        authStore.tokenListener = true
                        FirebasePlugin.onApnsTokenReceived(async(apnsToken) => {
                          console.info('setting APN token')
                          FirebasePlugin.onTokenRefresh(async (fcmToken) => {
                            // Refresh fcm token
                            authStore.fcmToken = fcmToken
                            const response = await methods.mutation([{
                              action: 'insert',
                              table: 'tokens',
                              data: {
                                userID: authStore.account?.id,
                                token: fcmToken,
                                type: 'fcm'
                              }
                            }], null, null, true)
                            console.info(response)
                          }, function(error) {
                              console.error(error);
                          });
                          resPlug(true)
                        }, function(error) {
                            console.error(error);
                            rejPlug(error);
                        })
                      }
                    }
                  })
                } else {
                  console.info('hasPermission')
                  authStore.tokenListener = true
                  FirebasePlugin.onMessageReceived(function(message) {
                    if(message.messageType === "notification"){
                      console.info(message)
                      authStore.setLastPushTimestamp(Date.now())
                    }
                  }, function(error) {
                      console.error(error);
                  });
                  FirebasePlugin.onTokenRefresh(async (fcmToken) => {
                    // Refresh fcm token
                    authStore.fcmToken = fcmToken
                    const response = await methods.mutation([{
                      action: 'insert',
                      table: 'tokens',
                      data: {
                        userID: authStore.account?.id,
                        token: fcmToken,
                        type: 'fcm'
                      }
                    }], null, null, true)
                    console.info(response)
                    resPlug(true)
                  }, function(error) {
                      console.error(error);
                      rejPlug(error);
                  });
                }
              })
            }
          })
        }
        // GET CREDENTIALS FROM PLUGIN
        firebaseTools['signInWithCredential'] = async (credential) => {
          console.info('using plugin signIn with Credential')
          return new Promise((resPlug, rejPlug) => {
            FirebasePlugin.signInWithCredential(credential, () => {
              //Get user data
              FirebasePlugin.getCurrentUser(async (userPlugin: { idToken:string }) => {
                try {
                  $vfm.show({
                      component: vsModal,
                      bind: { type: 'preloader' },
                    }, { title: 'Ingresando...'})
                  const customToken = await axios.post('authenticate', {
                    jwt: userPlugin.idToken,
                  })
                  const userCredential = await signInWithCustomToken(firebaseAuth, customToken.data.customToken)
                  const user = userCredential.user
                  const token = await userCredential.user.getIdToken(false)
                  resPlug({ user, token })
                } catch (error) {
                  rejPlug(error)
                } finally {
                  $vfm.hideAll()
                  FirebasePlugin.signOutUser(() => {
                    rejPlug('Error signing in')
                  }, rejPlug)
                }
              })
            }, rejPlug)
          })
        }
        firebaseTools['signInWith'] = async (providerType: string, loginData: {email:string, verificationCode:string}) => {
          return new Promise(async (resPlug, rejPlug) => {
            if (providerType === 'otp') {
              try {
                const customToken = await axios.post('useOTP', {
                  email: loginData.email,
                  otp: loginData.verificationCode
                })
                const userCredential = await signInWithCustomToken(firebaseAuth, customToken.data.customToken)
                const user = userCredential.user
                const token = await userCredential.user.getIdToken(false)
                resPlug({ user, token })
              } catch (error) {
                rejPlug(error)
              }
            } 
            if (providerType === 'google') {
              FirebasePlugin.authenticateUserWithGoogle('193142801973-cktbfsknim407klg51do65gvl6e81aci.apps.googleusercontent.com',(credential) => {
                FirebasePlugin.signInWithCredential(credential, () => {
                  //Get user data
                  FirebasePlugin.getCurrentUser(async (userPlugin: { idToken:string }) => {
                    try {
                      $vfm.show({
                          component: vsModal,
                          bind: { type: 'preloader' },
                        }, { title: 'Ingresando...'})
                      const customToken = await axios.post('authenticate', {
                        jwt: userPlugin.idToken,
                      })
                      const userCredential = await signInWithCustomToken(firebaseAuth, customToken.data.customToken)
                      const user = userCredential.user
                      const token = await userCredential.user.getIdToken(false)
                      resPlug({ user, token })
                    } catch (error) {
                      rejPlug(error)
                    } finally {
                      $vfm.hideAll()
                      FirebasePlugin.signOutUser(() => {
                        rejPlug('Error signing in')
                      }, rejPlug)
                    }
                  })
                }, rejPlug)
              }, rejPlug)
            }
            if (providerType === 'microsoft.com') {
              FirebasePlugin.authenticateUserWithMicrosoft((credential:any)=>{
                  FirebasePlugin.signInWithCredential(credential, () => {
                    //Get user data
                    FirebasePlugin.getCurrentUser(async (userPlugin: { idToken:string }) => {
                      try {
                        $vfm.show({
                          component: vsModal,
                          bind: { type: 'preloader' },
                        }, { title: 'Ingresando...'})
                        const customToken = await axios.post('authenticate', {
                          jwt: userPlugin.idToken,
                        })
                        const userCredential = await signInWithCustomToken(firebaseAuth, customToken.data.customToken)
                        const user = userCredential.user
                        const token = await userCredential.user.getIdToken(false)
                        resPlug({ user, token })
                      } catch (error) {
                        rejPlug(error)
                      } finally {
                        $vfm.hideAll()
                        FirebasePlugin.signOutUser(() => {
                          rejPlug('Error signing in')
                        }, rejPlug)
                      }
                    })
                  }, rejPlug)
                // signInWithCredential(firebaseAuth, credential)
              }, rejPlug, 'es_CO')
            }
            if (providerType === 'apple.com') {
              FirebasePlugin.authenticateUserWithApple((credential:any)=>{
                  FirebasePlugin.signInWithCredential(credential, () => {
                    //Get user data
                    FirebasePlugin.getCurrentUser(async (userPlugin: { idToken:string }) => {
                      try {
                        $vfm.show({
                          component: vsModal,
                          bind: { type: 'preloader' },
                        }, { title: 'Ingresando...'})
                        const customToken = await axios.post('authenticate', {
                          jwt: userPlugin.idToken,
                        })
                        const userCredential = await signInWithCustomToken(firebaseAuth, customToken.data.customToken)
                        const user = userCredential.user
                        const token = await userCredential.user.getIdToken(false)
                        resPlug({ user, token })
                      } catch (error) {
                        rejPlug(error)
                      } finally {
                        $vfm.hideAll()
                        FirebasePlugin.signOutUser(() => {
                          rejPlug('Error signing in')
                        }, rejPlug)
                      }
                    })
                  }, rejPlug)
                // signInWithCredential(firebaseAuth, credential)
              }, rejPlug, 'es_CO')
            }
            if (providerType === 'facebook.com') {
              facebookConnectPlugin.login(['email','public_profile'], (userData) => {
                  console.log('UserInfo: ', userData)
                  facebookConnectPlugin.getAccessToken(async (token) =>{
                    const credential = FacebookAuthProvider.credential(token)
                    try {
                      $vfm.show({
                        component: vsModal,
                        bind: { type: 'preloader' },
                      }, { title: 'Ingresando...'})
                      const result = await signInWithCredential(firebaseAuth, credential)
                      const user = result.user
                      resPlug({user, token})
                    } catch (error) {
                      rejPlug(error)
                    } finally {
                      $vfm.hideAll()
                      FirebasePlugin.signOutUser(() => {
                        rejPlug('Error signing in')
                      }, rejPlug)
                    }
                  })
                },
                (error) => {
                  rejPlug(error)
                }
              )
            }
            if (providerType === 'phone') {
              const authStore = useAuthStore()
              const timeOutDuration = 60
              FirebasePlugin.verifyPhoneNumber(function(credential) {
                  if(credential.instantVerification){
                      if(authStore.awatingSMS){
                        authStore.setAwaitingSMS(false)
                      }
                      FirebasePlugin.signInWithCredential(credential, () => {
                        //Get user data
                        FirebasePlugin.getCurrentUser(async (userPlugin: { idToken:string }) => {
                          try {
                            $vfm.show({
                              component: vsModal,
                              bind: { type: 'preloader' },
                            }, { title: 'Ingresando...'})
                            const customToken = await axios.post('authenticate', {
                              jwt: userPlugin.idToken,
                            })
                            const userCredential = await signInWithCustomToken(firebaseAuth, customToken.data.customToken)
                            const user = userCredential.user
                            const token = await userCredential.user.getIdToken(false)
                            resPlug({ user, token })
                          } catch (error) {
                            rejPlug(error)
                          } finally {
                            $vfm.hideAll()
                            FirebasePlugin.signOutUser(() => {
                              rejPlug('Error signing in')
                            }, rejPlug)
                          }
                        })
                      }, rejPlug)
                  }else{
                    authStore.setAwaitingSMS(true)
                    console.info('credential with no code, should ask for it')
                    resPlug(credential)
                  }
              }, function(error) {
                  console.error("Failed to verify phone number: " + JSON.stringify(error))
                  rejPlug(error)
              }, loginData.phone, timeOutDuration)
            }
          })
        }
        res(true)
      })
    } else {
      // Only inject maps //TODO: this should be done in the plugin beforemounted vsGoogleMap
      let system = `/cordova.js`
      let cordovaScript = document.createElement('script')
      cordovaScript.setAttribute('src', system)
      cordovaScript.setAttribute('type', 'text/javascript')
      document.getElementsByTagName('head')[0].appendChild(cordovaScript)
      document.addEventListener('deviceready', async () => {
        firebaseTools['signInWith'] = async (providerType: string, loginData: {email:string, verificationCode:string}) => {
          let provider = null
          if (providerType === 'google') {
            provider = new GoogleAuthProvider()
            provider.addScope('https://www.googleapis.com/auth/userinfo.email')
          }
          if (providerType === 'microsoft.com') {
            provider = new OAuthProvider('microsoft.com')
            provider.setCustomParameters({
              // Force re-consent.
              prompt: 'consent',
            })
          }
          if (providerType === 'facebook.com') {
            provider = new FacebookAuthProvider()
            provider.addScope('email')
            provider.addScope('public_profile')
            provider.setCustomParameters({
              'auth_type': 'rerequest',
              'display': 'popup',
            })
          }
          if (providerType === 'apple.com') {
            provider = new OAuthProvider('apple.com')
            provider.addScope('email')
            provider.addScope('name')
            provider.setCustomParameters({
              locale: 'es_ES'
            })
          }
          if (provider !== null) {
            try {
              firebaseAuth.useDeviceLanguage()
              const result = await signInWithPopup(firebaseAuth, provider)
              console.info(result)
              let credential
              switch (providerType) {
                case 'google':
                  credential = GoogleAuthProvider.credentialFromResult(result)
                  break
                case 'microsoft.com':
                  credential = OAuthProvider.credentialFromResult(result)
                  break
                case 'apple.com':
                  credential = OAuthProvider.credentialFromResult(result)
                  break
                case 'facebook.com':
                  credential = FacebookAuthProvider.credentialFromResult(result)
                  break
              }
              const token = credential?.accessToken
              const user = result.user
              return { user, token }
            } catch (error: any) {
              const errorCode = error.code
              const errorMessage = error.message
              // The email of the user's account used.
              const email = error.email
              // The AuthCredential type that was used.
              const credential = GoogleAuthProvider.credentialFromError(error)
              console.error(email, credential, errorCode, errorMessage)
              return { user: null, token: null }
            }
          } else if (providerType === 'otp') {
            const customToken = await axios.post('useOTP', {
              email: loginData.email,
              otp: loginData.verificationCode
            })
            const userCredential = await signInWithCustomToken(firebaseAuth, customToken.data.customToken)
            const user = userCredential.user
            const token = await userCredential.user.getIdToken(false)
            return { user, token }
          }
        }
      })
      res(true)
    }
  })
}
