import Typesense from 'typesense'

export const client = new Typesense.Client({
  'nodes': [{
    'host': 'search.rutasegura.co', // For Typesense Cloud use xxx.a1.typesense.net
    'port': 443,      // For Typesense Cloud use 443
    'protocol': 'https'   // For Typesense Cloud use https
  }],
  'apiKey': 'Mycomplexpassword#6546',
  'connectionTimeoutSeconds': 2
})