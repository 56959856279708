import { createApp } from 'vue'
import App from './App.vue'

import { initConfig, vfmPlugin } from '@isagaco/vuesaga'
import './index.css'
import '@isagaco/vuesaga/dist/style.css'

import axios from 'axios'
axios.defaults.baseURL =
import.meta.env.DEV
? `http://${window.location.hostname}:5001/buseticas-7f9fd/us-central1/api/`
: 'https://us-central1-buseticas-7f9fd.cloudfunctions.net/api/'

Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(
  promises.map(p => p
      .then(value => ({
          status: "fulfilled",
          value
      }))
      .catch(reason => ({
          status: "rejected",
          reason
      }))
  )
));

import urql from '@urql/vue'
import { urqlConfig } from './services/hasura'
import { initFirebase, firebaseTools } from './services/firebase'
import { User } from 'firebase/auth'
import { state } from './services/localStorage'

import router from './router'
import { createPinia } from 'pinia'

let vueInstance: any = null
let ref:any = null

async function updateToken (user: User) {
   const {token:jwt, jwtRefresh}= await firebaseTools.getCurrentUserJWT()
   state.value.jwt = jwt
   state.value.jwtRefresh = jwtRefresh
}

async function checkFirebaseUser () {
  // Check if cordova
  await initFirebase()
  initConfig()
  if (await firebaseTools.getCurrentUser()) {
    vueInstance = initVue()
  }
  firebaseTools.onIdTokenChanged((user) => {
    if (user) {
      if (ref === null) {
        ref = firebaseTools.getRef(`metadata/${user.uid}/refreshTime`)
        firebaseTools.listen(ref, async (snap) => {
          updateToken(user)
        })
      } else {
        updateToken(user)
      }
    }
    if (vueInstance === null) {
      vueInstance = initVue()
    }
  })
}
function initVue () {
  createApp(App)
    .use(router)
    .use(createPinia())
    .use(urql, urqlConfig)
    .use(vfmPlugin)
    .mount('#app')
}

checkFirebaseUser()